<template>
  <v-parallax
    dark
    class="rounded home__jumbo"
    src="@/assets/img/jumbo.jpg"
    :height="jumboHeight ? jumboHeight : undefined"
  >
    <v-container class="home__jumbo__container rounded my-2 my-lg-4">
      <v-row justify="center">
        <v-col cols="12" lg="6">
          <h1 class="text-center font-weight-light text-h3">
            <div class="home__jumbo__heading d-inline-block px-1 px-md-2">
              {{ custom.title }}
            </div>
          </h1>
          <h2 class="text-h5 text-center font-weight-light">
            Développeur Freelance - FullStack
          </h2>
          <div class="d-flex justify-space-around flex-wrap">
            <div class="mx-4">Node.js</div>
            <div class="mx-4">TypeScript</div>
            <div class="mx-4">React Native</div>
            <div class="mx-4">Vue</div>
            <div class="mx-4">AWS</div>
            <div class="mx-4">DevOps</div>
          </div>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-row justify="center">
        <v-col
          cols="12"
          lg="9"
          class="text-justify d-flex flex-lg-row flex-column align-center"
        >
          <v-avatar size="150">
            <v-img src="@/assets/img/face.jpg"></v-img>
          </v-avatar>
          <div class="contenu">
            <p class="subtitle-1 ml-lg-4">
              D'une grande <strong>polyvalence</strong> et
              <strong>à l'écoute</strong> de vos besoins, je vous accompagne
              dans la définition, la conception, et la réalisation de vos
              <strong>applications</strong> et vos
              <strong>développements web et logiciels</strong> <br />
              De la Web App <strong>React</strong> ou Vue.js, au
              <strong>Backend Node.js</strong> rédigé en
              <strong>TypeScript</strong>, en passant la mise en place de <strong>CI/CD</strong>,
              je réalise des prestations de développement
              <strong>clés en main</strong> ou à la carte, dans la région de
              Toulouse, mais aussi <strong>partout en France</strong> en
              télétravail.
            </p>
            <p class="subtitle-1 ml-lg-4"><strong>Certified AWS Cloud Practitioner</strong>, je vous accompagne dans votre transition ou vos déploiements cloud sur Amazon Web Serviecs</p>
            <p class="subtitle-1 ml-lg-4">
              <strong>Organisme de formation</strong> déclaré auprès de la DREETS, j'interviens
              également au sein de votre établissement pour dispenser des
              formations centrées sur les métiers du développement
            </p>
          </div>
        </v-col>
      </v-row>
      <v-divider class="mb-3"></v-divider>
      <v-row justify="center">
        <v-btn depressed x-large left color="secondary" to="/contact">
          <v-icon x-large class="ml-n1">mdi-email</v-icon>
          <span class="ml-2">Contactez-moi!</span>
        </v-btn>
      </v-row>
    </v-container>
  </v-parallax>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "Jumbo",
  data() {
    return {
      jumboHeight: null,
    };
  },
  computed: {
    ...mapState(["custom"]),
  },
  methods: {
    toYoutube() {
      window.open("https://youtube.com/c/foxhoundulm", "_blank");
    },
  },
  mounted() {
    if (window.innerWidth > 950) {
      this.jumboHeight = 650;
    } else if (window.innerWidth > 500) {
      this.jumboHeight = 800;
    } else if (window.innerWidth > 380) {
      this.jumboHeight = 910;
    } else {
      this.jumboHeight = 1060;
    }
    const that = this;
    window.addEventListener("resize", () => {
    if (window.innerWidth > 950) {
      that.jumboHeight = 700;
    } else if (window.innerWidth > 500) {
      that.jumboHeight = 800;
    } else if (window.innerWidth > 380) {
      that.jumboHeight = 910;
    } else {
      that.jumboHeight = 1060;
    }
    });
  },
};
</script>

<style lang="scss" scoped>
a {
  text-decoration: none;
}

.home {
  &__jumbo {
    &__heading {
      font-variant: small-caps;
    }

    &__container {
      background-color: rgba(black, 0.6);
    }
  }
}
</style>