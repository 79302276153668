<template>
  <v-container class="home">
    <Jumbo />
    <v-row justify="center">
      <v-col cols="12" sm="6" lg="4" v-for="card in cards" :key="card.title">
        <Card outlined :card="card" />
      </v-col>
    </v-row>
    <Content />
  </v-container>
</template>

<script>
import Jumbo from "@/components/Jumbo";
import Card from "@/components/Card";
import Content from "@/components/home/Content";

export default {
  name: "Home",
  components: {
    Jumbo,
    Card,
    Content,
  },
  data() {
    return {
      cards: [
        {
          title: "Rigueur",
          img: "rigueur.jpg",
          pretext:
            "Des livrables propres et lisibles, un code maintenable et commenté, ça fait la différence!",
          posttext:
            "Le développement s'effectue toujours dans le respect des exigences de sécurité et les standards en vigueur. Je développe quasi-exclusivement en TypeScript, en amenant mon expertise dans le langage au service de la robustesse du code",
        },
        {
          title: "Flexibilité",
          img: "flexibilite.jpg",
          pretext:
            "Dans vos locaux dans la région de Toulouse ou en télétravail sans frontières, je réalise vos projets avec sérieux",
          posttext:
            "Livrer un travail réalisé avec soin quelles que soient les conditions, c'est ma priorité",
        },
        {
          title: "Harmonie",
          img: "harmonie.jpg",
          pretext:
            "Parce que votre site ou votre application sont les fenêtres donnant sur vos projets, qu'ils renvoient une image harmonieuse est indispensable",
          posttext:
            "Sensible aux techniques modernes comme le Material Design, je réalise des prestations modernes et visuellement élaborées",
        },
      ],
    };
  },
  beforeMount() {
    window.document.title =
      "Développeur d'Applications à Toulouse - Julien FERAND";
  },
};
</script>