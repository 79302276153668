<template>
    <v-card outlined>
        <v-card-title>
            <h2 class="text-h4 font-weight-light">{{ title }}</h2>
        </v-card-title>
        <v-container>
            <v-row>
                <v-col cols=12>
                    <h3 class="text-h5 font-weight-light">Pour qui ?</h3>
                </v-col>
            </v-row>
            <v-row align="center" justify='center'>
                <v-col cols=12 lg=9 class="text-justify">
                    Que vous soyez un indépendant travaillant seul ou une entreprise de taille plus conséquente, les
                    besoins
                    en développement ont progressé de façon exponentielle ces dernières années.
                    Disposer d'applications propres, modernes et fonctionnelles est devenu indispensable dans une
                    économie toujours plus dématérialisée.<br />
                    Que vous souhaitiez réaliser une application web, mettre en place un serveur backend, 
                    développer vos solutions IoT ou encore mettre en place une architecture cloud sur AWS, je m'adapte avec polyvalence à votre besoin et
                    propose des solutions adaptées à vos contraintes techniques et financières.
                </v-col>
                <v-col cols=12 sm=8 lg=3>
                    <v-img aspect-ratio="1.778" src="@/assets/img/pourqui.jpg" class="rounded"></v-img>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols=12>
                    <h3 class="text-h5 font-weight-light">Comment ?</h3>
                </v-col>
            </v-row>
            <v-row align="center" class="flex-lg-row-reverse" justify='center'>
                <v-col cols=12 lg=9 class="text-justify">
                    La flexbilité est un axiome important du travail de développeur Web freelance. Une prestation pour
                    une TPE ou un indépendant peut tout à fait s'effectuer en télétravail, sans impact sur la qualité de
                    la prestation ou le suivi, grâce aux moyens de visioconférence modernes.
                    <br />Pour une entreprise de taille plus conséquente disposant de procédures qualité nécessitant une
                    prestation in-situ (sous réserve de respect des protocoles sanitaires en vigueur),
                    j'interviens chez le client pour réaliser les prestations de développement web sur Toulouse et toute
                    la région toulousaine.
                </v-col>
                <v-col cols=12 sm=8 lg=3>
                    <v-img aspect-ratio="1.778" src="@/assets/img/comment.jpg" class="rounded"></v-img>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols=12>
                    <h3 class="text-h5 font-weight-light">L'aspect technique</h3>
                </v-col>
            </v-row>
            <v-row align="center" justify='center'>
                <v-col cols=12 lg=9 class="text-justify">
                    Les technologies d'hier ne sont pas celles d'aujourd'hui et ne seront pas celles de demain.<br />
                    En maintenant une veille technologique essentielle et en choisissant des solutions techniques
                    mordernes, maintenues et évolutives,
                    j'assure au client une obsolescence limitée de son code et un portage facilité lors de la mise en
                    place de nouvelles technologies.
                    En proposant une expertise dans des technologies modernes et maintenues, telles que Node.js, TypeScript, React & React Native, Vue.js, AWS... j'apporte à votre projet fonctionnalité, robustesse et maintenabilité.
                </v-col>
                <v-col cols=12 sm=8 lg=3>
                    <v-img aspect-ratio="1.778" src="@/assets/img/technique.jpg" class="rounded"></v-img>
                </v-col>
            </v-row>
        </v-container>
    </v-card>
</template>

<script>
    export default {
        name: "Content",
        data() {
            return {
                title: 'Le développement',
            }
        }
    }
</script>

<style lang="scss" scoped>
    .v-card{
        &__title{
            word-break: break-word;
        }
    }
</style>